<template>
  <div class="infinite-table">
    <InfiniteScrollWrapper
      class="infinite-table__scroll"
      ref="scroll"
      :loading="loading"
      @reachedTop="getPreviousPage"
      @reachedBottom="getNextPage"
    >
      <slot name="header" />
      <list
        ref="listWrapper"
        :columns="columns"
        :items="transformedItems"
        :hasFilters="hasFilters"
        :resourceLabel="resourceLabel"
        :css="listClasses"
        @rowClick="$emit('click', $event)"
      >
        <template v-slot:add>
          <slot name="add" />
        </template>
        <template v-for="(index, name) in $slots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </list>
    </InfiniteScrollWrapper>
  </div>
</template>

<script>
import { isEqual, debounce } from "lodash-es";
import NotifyMixin from "@/mixins/NotifyMixin";
import InfiniteScrollWrapper from "@/components/list/InfiniteScrollWrapper";
import List from '@/components/list/List';

export default {
  mixins: [NotifyMixin],

  components: {
    InfiniteScrollWrapper,
    List,
  },

  props: {
    dataProvider: {
      type: Object,
      required: true,
    },

    resource: {
      type: String,
      required: true,
    },

    resourceLabel: {
      type: String,
      default: 'items',
    },

    columns: {
      type: Array,
      required: true,
    },

    filters: {
      type: Object,
      required: false,
    },

    additionalRequestParams: {
      type: Object,
      default: () => ({}),
    },

    pageSize: {
      type: Number,
      required: false,
      default: 20,
    },

    maxItems: {
      type: Number,
      required: false,
      default: 100,
    },

    listClasses: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: [
    'click',
    'initialized',
  ],

  data() {
    return {
      loading: false,
      hasPrevious: false,
      hasNext: true,
      items: null,
      expandedId: null,
    };
  },

  computed: {
    hasFilters(){
      return !!(this.filters && Object.keys(this.filters).length);
    },

    transformedItems(){
      return this.items?.map(({value}) => ({...value})) || [];
    }
  },

  watch: {
    items() {
      const { scroll } = this.$refs;
      const { head, list } = this.$refs.listWrapper.$refs;

      if (!head || !list || !scroll) {
        return;
      }

      const {bottom: headBottom} = head.getBoundingClientRect();

      const firstVisible = Array
        .from(list.$el.childNodes)
        .find(el => el.getBoundingClientRect().bottom > headBottom);

      if (!firstVisible) {
        return;
      }

      const oldSecondVisible = firstVisible.nextElementSibling;

      this.$nextTick(() => {
        if (oldSecondVisible !== firstVisible.nextElementSibling) {
          return;
        }

        scroll.$el.scrollTop += firstVisible.getBoundingClientRect().top - headBottom;
      });
    },

    filters(newValue, oldValue) {
      if (newValue.q === oldValue.q
       && !isEqual(newValue, oldValue))
      {
        this.initialize();
      }
    },

    'filters.q': debounce(function(){
       this.initialize();
    }, 500),
  },

  methods: {
    initialize() {
      this.hasPrevious = false;
      this.hasNext = true;
      this.loading = true;

      const requestParameters = this.makeRequestParameters();

      this.dataProvider.getList(this.resource, requestParameters)
        .then(({items, totalSize}) => {
          if (!isEqual(requestParameters, this.makeRequestParameters())) {
            return;
          }

          this.hasNext = items.length >= this.pageSize;
          this.items = items;

          /**
           * Emits when table was initialized
           */
          this.$emit('initialized', items, totalSize);
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },

    getPreviousPage() {
      if (!this.hasPrevious) return;

      this.loading = true;

      this.dataProvider.getList(this.resource, this.makeRequestParameters(this.items[0].token, 'BACK'))
        .then(({items}) => {
          this.hasPrevious = items.length >= this.pageSize;
          this.items = [...items.reverse(), ...this.items];

          if (this.items.length > this.maxItems) {
            this.items = this.items.slice(0, -(this.items.length - this.maxItems));
            this.hasNext = true;
          }
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },

    getNextPage() {
      if (!this.hasNext) return;

      this.loading = true;

      this.dataProvider.getList(this.resource, this.makeRequestParameters(this.items[this.items.length - 1].token, 'FORWARD'))
        .then(({items}) => {
          this.hasNext = items.length >= this.pageSize;
          this.items = [...this.items, ...items];

          if (this.items.length > this.maxItems) {
            this.items = this.items.slice(this.items.length - this.maxItems);
            this.hasPrevious = true;
          }
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },

    makeRequestParameters(token, dir) {
      return {
        ...this.filters,
        ...this.additionalRequestParams,
        dir,
        token,
        limit: this.pageSize,
      };
    },

    getItems() {
      return this.items;
    },

    modifyItems(cb) {
      const items = cb(this.items);

      if (items) {
        this.items = items;
      }
    },

    scrollToItem(id) {
      const { scroll } = this.$refs;
      const { head, list } = this.$refs.listWrapper.$refs;

      const index = this.transformedItems.findIndex(item => item.id === id);

      if (index < 0) {
        return;
      }

      const {bottom: headBottom} = head.getBoundingClientRect();
      scroll.$el.scrollTop += list.$el.children[index].getBoundingClientRect().top - headBottom;
    },
  },

  mounted() {
    this.initialize();
  }
};
</script>

<style scoped>
.infinite-table {
  @apply overflow-y-hidden;

  &__scroll {
    @apply overflow-y-auto h-full;
  }
}
</style>
