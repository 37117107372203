<template>
  <FinalForm
    ref="form"
    :initial-values="values"
    @change.self="handleChange"
  >
    <template v-slot="props">
      <div>
        <div
          class="flex items-center pb-4"
          :class="filters.length ? 'justify-between' : 'justify-end'"
        >
          <div v-if="filters.length" class="flex flex-wrap items-center">
            <span class="font-frank font-400 mr-3">viewing:</span>
            <div
              v-for="filter of filters"
              :key="filter.name"
              class="font-frank font-medium text-xs bg-blue-100 text-blue-700 py-1 mr-1"
            >
              <span class="px-3">{{ filter.text(values[filter.name], values) }}</span>
              <button
                v-if="filter.clearable"
                @click="clearFilterValue(filter.name)"
                class="cancel-filter"
              >&times;</button>
            </div>
          </div>
          <div
            class="flex items-center"
            :class="controlsClass"
          >
            <TextField
              class="search-query"
              name="searchQuery"
              :placeholder="searchPlaceholderText"
              clearable
            >
              <template v-slot:leading-icon>
                <Icon name="search2" class="w-4 h-4 text-graphite-800"/>
              </template>
            </TextField>
            <FilterIcon
              v-if="this.$slots.default"
              class="w-6 h-6 ml-3 cursor-pointer"
              :class="{'text-blue-700': expanded}"
              @click="expanded = !expanded"/>
          </div>
        </div>
        <div class="flex justify-end">
          <div
            class="filters-form bg-blue-50 border border-blue-100 p-5 mt-6"
            :class="{hidden: !expanded}"
          >
            <div class="flex items-end">
              <slot name="default"></slot>
              <button :disabled="!canReset" class="btn-primary" @click="props.reset">reset</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FinalForm>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import TextField from "@/components/form/TextField";
import FilterIcon from "@/components/ui/icons/FilterIcon";
import Icon from "@/components/ui/Icon";

export default {
  components: {FinalForm, TextField, FilterIcon, Icon},

  props: {
    filters: {
      type: Array,
      required: true,
    },

    values: {
      type: Object,
      required: true,
    },

    searchPlaceholderText: {
      type: String,
      default: 'Search',
    },

    controlsClass: {
      type: [String, Array],
      default: 'w-1/3',
    }
  },

  emits: ['change'],

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    canReset() {
      return this.filters.some(f => f.clearable);
    },
  },

  methods: {
    handleChange(formState) {
      this.$emit('change', formState.values);
    },

    clearFilterValue(name) {
      const {finalForm, formState} = this.$refs.form;

      const oldValue = formState.values[name];

      const newValue = Array.isArray(oldValue)
        ? oldValue.filter(value => value !== this.values[name])
        : null;

      finalForm.change(name, newValue);
    },
  },
};
</script>
