<template>
  <div>
    <div
      v-if="!withTooltip"
      ref="text"
      class="trimmed-text"
      @mouseenter="handleMouseEnter"
    >
      <slot>{{ text }}</slot>
    </div>

    <tooltip v-else>
      <!-- @slot Default to text prop -->
      <template v-slot:default>
        <slot>{{ text }}</slot>
      </template>
      <!-- @slot Label prop -->
      <template v-slot:label>
        <div
          ref="text"
          class="trimmed-text"
          @mouseenter="handleMouseEnter"
        >
          <slot>{{ text }}</slot>
        </div>
      </template>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/ui/Tooltip";

export default {
  name: "TextTrimmer",
  components: {
    Tooltip,
  },
  props: {
    /**
     * Original text
     */
    text: {
      type: String,
    },
    /**
     * Whether to show Tooltip with the fully visible text
     */
    noTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      withTooltip: false,
    };
  },
  methods: {
    handleMouseEnter() {
      const el = this.$refs.text;

      this.withTooltip = !this.noTooltip && !!el && el.offsetHeight < el.scrollHeight;
    },
  },
};
</script>

<style scoped>

.trimmed-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: anywhere;
}

.tooltip-wrapper {
  display: block;
}

</style>
